.content {
  background-color: #F7FBFF;
}
.rightSumUpList {
  height: 68vh;
  overflow-y: scroll;
}
.rightSumUpCenter {
  text-align:  center;
}
.homeLeftMap .MuiPaper-root {
  padding: 0px;
}
@media screen and (max-width: 600px) {
  #mapHome {
    display: none;
  }
  .rightSumUpList {
    height: 72vh;
    overflow-y: scroll;
  }
  .breadcrumb, .filterBlock {
    display: none;
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  #mapHome {
    display: none;
  }
  .rightSumUpList {
    height: 72vh;
    overflow-y: scroll;
  }
}

@media screen and (min-width: 601px) {
  #mapHome {
    height: 76vh;
  }
}
.filterOrderSpan {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);  /*  for IE  */

  /*  for browsers supporting webkit (such as chrome, firefox, safari etc.). */
  -webkit-transform: rotate(90deg);
  display: inline-block;
  position: relative;
  top: 3px;
  right: -2px;
  font-weight: bold;
}
.breadcrumb {
  font-size: 10pt;
  color: rgb(30, 88, 145);
}
.marinaFoundNumber {
  font-weight: bold;
  font-size: 12pt;
}
.clearSearch {
  position: absolute;
  top: 5px;
  display: inline;
}
.clearSearch:hover {
  cursor: pointer;
}
