.searchFieldsMain {
	background-color: white;
	height: 80px;
	margin-left: 5%;
	width:  90%;
  	border-radius: 5px; 
  	color:  #BDBDBD;
}
.locationsWidthPopOver, .boatSizePopOver {
    width: 500px;
 }
.datesPopOver {
	width:  700px;
}
.locationsWidthPopOver, .datesPopOver, .boatSizePopOver {
    border: 1px solid #dddddd;
    box-shadow: 0 1px 2px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 5%);
    transition: box-shadow 0.2s ease;
    padding: 20px;
}
.searchElement:hover {
	cursor:  pointer;
	background-color: rgba(7, 125, 193, 0.2);
}

.searchFieldsElement {
	padding-left: 10px;
	padding-right: 10px;
}
.searchFieldBoatSize {
	text-align: left;
}
.searchFieldDates {
	text-align: center;
}
.searchFieldLocation {
	text-align: right;
}

.MuiInputBase-root:before,
.MuiInputBase-root:after {
	border-bottom: inherit !important;
}
@media screen and (max-width: 400px) {
	.locationsWidthPopOver, .datesPopOver, .boatSizePopOver {
		width:  300px;
	}
}
@media screen and (min-width: 401px) and (max-width: 600px) {
	.locationsWidthPopOver, .datesPopOver, .boatSizePopOver {
		width:  410px;
	}
}
@media screen and (max-width: 900px) {
	.searchFieldsMain {
		background-color: inherit;
	  	height: inherit;
	}
	.searchFieldsElement {
		background-color: white;
	  	border-radius: 5px;
	  	padding: 10px; 
	  	margin: 10px !important;
	}
	.searchFieldBoatSize, .searchFieldLocation {
		text-align: center;
	}
}