.homeContent {
	background-size: cover;
	background-image:  url("../../public/images/background-img-marina.jpg");
    height: calc(100vh - 8vh);
    min-width: 100%;
    margin-top: 8vh;
    vertical-align: middle;
}
.titleSearch {
	position: absolute;
    bottom: 25.5rem;
    left: 50%;
    transform: translate(-50%,15%);
    width: 100%;
    text-align:  center;
    color:  white;
}
.homeTitle {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 64px !important;
    line-height: 80px !important;
    text-align: center;
    letter-spacing: 0.2px !important;

    width: 95%;
    padding-left: 2.5%;
}
@media screen and (max-width: 900px) {
    .titleSearch {
        bottom: inherit;
        top:  20vh;
    }
    .homeTitle {
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 34px !important;
        line-height: 38px !important;
    }
}