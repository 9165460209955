a:visited {
  color: inherit;
}
.searchContent {
  background: #1E5891;
  color: white;
  height: 15vh;
  margin-top: 10vh;
}
@media screen and (max-width: 600px) {
  .searchContent {
    height: auto;
    margin-top: 10vh;
  }
  nav {
    height: 6vh;
  }
}
@media screen and (min-width: 601px) and (max-width: 900px) {
  .searchContent {
    height: auto;
    margin-top: 9vh;
  }
  nav {
    height: 6vh;
  }
}
