.searchBarMobile {
	display: none;
}
@media screen and (max-width: 900px) {
	.searchBarDesktop {
		display: none;
	}
	.searchBarMobile {
		display: block;
	}
}
@media screen and (max-width: 1200px) {
	.searchButtonTitle {
		display: none;
	}
	.searchButton .buttonTitle {
		display: none;
	}
}
@media screen and (min-width: 1201px) {
  .searchButton {
    min-width: 160px !important;
  }
}
