.sfnh-searchFieldsMain {
	background-color: white;
	height: 80px;
	margin-left: 5%;
	width:  90%;
  	border-radius: 5px; 
  	color:  #BDBDBD;
}
.locationsWidthPopOver, .boatSizePopOver {
    width: 500px;
 }
.datesPopOver {
	width:  700px;
}
.locationsWidthPopOver, .datesPopOver, .boatSizePopOver {
    border: 1px solid #dddddd;
    box-shadow: 0 1px 2px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 5%);
    transition: box-shadow 0.2s ease;
    padding: 20px;
}
.sfnh-searchElement:hover {
	cursor:  pointer;
	background-color: rgba(7, 125, 193, 0.2);
}

.sfnh-searchFieldsElement {
	padding-left: 10px;
	padding-right: 10px;
}
.sfnh-searchFieldBoatSize {
	text-align: left;
}
.sfnh-searchFieldDates {
	text-align: center;
}
.sfnh-searchFieldLocation {
	text-align: right;
}

.MuiInputBase-root:before,
.MuiInputBase-root:after {
	border-bottom: inherit !important;
}
.sfnh-searchFieldsMainGrid {
	padding-top: 2vh;
}

@media screen and (max-width: 600px) {
	.sfnh-searchFieldsMain {
  	    padding-top: 3px;
	}
}
@media screen and (max-width: 900px) {
	.sfnh-searchFieldsMain {
	  	height: inherit;
  	    padding-top: 10px;
  	    margin-bottom: 3px;
	}
	.sfnh-searchBarMobile .MuiGrid-item {
		padding-top: 0px !important;
	}
	.sfnh-searchFieldBoatSize, .sfnh-searchFieldLocation {
		text-align: center;
	}
	.sfnh-searchFieldsMainGrid {
		padding-top: 0px;
	}
	.sfnh-searchFieldBoatSize {
	  	border-radius: 0px 0px 5px 0px !important;
	  	padding: 0px !important;
	  	margin:  0px !important;
	    border-width: 0px 0px 0px 1px;
	    border-color: #C6D3DF;
	    border-style: solid;
	}
	.sfnh-searchFieldDates {
	  	border-radius: 0px 0px 0px 5px !important;
	}
	.sfnh-searchFieldLocation {
	  	border-radius: 5px 5px 0px 0px !important;
	    border-width: 0px 0px 1px 0px;
	    border-color: #C6D3DF;
	    border-style: solid;
	}
	.sfnh-searchFieldsElement {
		background-color: white;
	}
	.sfnh-searchFieldsElement label {
		display: none;
	}
	.sfnh-searchFieldsElement .MuiInputBase-root {
		margin-top:  inherit !important;
	}
	.sfnh-searchBarMobile .sfnh-searchFieldsElement {
	  	padding: 4px !important;
	  	margin: inherit !important;		
	}
	.sfnh-searchBarMobile .sfnh-searchFieldsElement input {
    	font: 10pt Inter !important;
    }
}