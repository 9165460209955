.my404Content {
	background-size: cover;
	background-image:  url("../../public/images/background-img-marina-404.jpg");
    height: calc(100vh - 8vh);
    min-width: 100%;
    margin-top: 8vh;
    vertical-align: middle;
}
.titleError {
	position: absolute;
    bottom: 25.5rem;
    left: 50%;
    transform: translate(-50%,15%);
    width: 100%;
    text-align:  center;
    color:  white;
}