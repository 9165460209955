.price {
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    color: #28292D; 
}
.heatColor {
    height: 10px;
    width: 40%;
    position: relative;
    bottom: 3px;
}
@media screen and (max-width: 600px) {
  .price {
    font-size: 28px;
  }
}