.priceSlideUp {
	position: fixed;
	top: 7vh;
	background-color: white;
	width: 100%;
	z-index: 1;
	box-shadow: 3px 6px 12px rgba(0, 0, 0, 0.1);
	padding-top: 10px;
}
.centerMarinaTitle, .rightMarinaPrices {
	text-align: center;
	z-index: 1;
}
.backButton {
	position: fixed;
	z-index: 1;
}
@media screen and (max-width: 600px) {
	.centerMarinaTitle div {
		color:  white;
		background-color: rgba(0, 0, 255, .4);
		margin-top:  10px;
		padding:  0px 10px 0px 10px;
	}
	.MuiPaper-root {
		padding: 0px !important;
	}
	.marinaFicheGridImg img {
		opacity:  0.6;
		max-width: 100vw;
	}
	.areasSelectionBlockContainer {
		padding-top: inherit !important;
	}
	.priceSlideUp {
		top: 8vh;
	}
}
@media screen and (min-width: 601px) {
	.centerMarinaTitle {
		color:  black;
	}
	.marinaFicheGridImg img {
		width: 40%;
		height: 'auto';
	}
}
.MuiPaper-root {
	border-radius: 0px !important;
	box-shadow:  none !important;
}
..MuiPaper-elevation1 {
	box-shadow:  none !important;
	border-radius: 0px !important;
}
.breadcrumb {
  font-size: 10pt;
  color: rgb(30, 88, 145);
  padding:  10px 0px 10px 0px;
}
.marinaTitle, .marinaLocation {
	padding: 0px 0px 10px 10px;
}
.yourLocation {
	color: #BDBDBD;
	padding:  10px;
}
.areasTitleNumber {
	color: rgb(30, 88, 145);
	font-style: normal;
	font-weight: 700;
	font-size: 13pt;
	line-height: 18px;
}
.areasSelectionBlock, .spotsSelectionBlock {
	border-radius: 10px;
	background-color: #F7FBFF;
	width: 90% !important;
	padding: 10px;
	margin-bottom: 5px;
}
.areasSelectionBlockContainer {
	box-shadow: 3px 6px 12px rgba(0, 0, 0, 0.1);
	padding-top: 20px;
}
.areaTitle {
  cursor: pointer;	
}
.areaTitle, .areaTitleSelected, .pricePeriodTitle {
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
	color: #28292D;	

	width: 100%;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  height: 29px;
  padding: 5px;
}
.areaTitleSelected {
	font-weight: bold;
	background-color: rgba(7, 125, 193, 0.2);
}
.pricePeriodTitle {
	padding-left: 10px;
}
.spotsTitleFrom, .priceDetailsInfo {
	font-size: 12px;
	line-height: 20px;
	text-align: right;
	color: #BDBDBD;	
}
.pricePeriod {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: rgb(30, 88, 145);
}
.otherMarinaTitle, .priceDetailsTitle {
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	color: #1E5891;	
}
.marinaTopInfo {
	padding-top: 0px !important
}
.marinaTopInfoContent {
	background-color: #FFF;
	border-radius: 0px 0px 11px 11px;
	box-shadow: 3px 6px 12px rgba(0, 0, 0, 0.1);
	overflow: hidden;
}
.marinaImg, .marinaImgMobile {
	width: 100%;
	vertical-align: middle;
	background-size: cover;
	background-position: center center;
	background-color: rgba(7, 125, 193, 0.2)
}
.marinaImgMobile {
	border-radius: 11px 11px 0px 0px;
}
.marinaImg {
	height: calc(100% - 150px);
}
.marinaImgMobile {
	height: 110px;
}
.content {
	min-height: 78vh;
}
.modalPhone {
	width: 400px;
}
.modalPhone a {
	text-decoration: none;
}
.dialogMakeRequest {
	width: 90%;
}
@media screen and (max-width: 900px) {
	.marinaImg {
		display: none;
	}
	.marinaTopInfoContact {
		height: 150px;
	}
	.marinaTitle {
		font-size: 14pt !important;
		font-weight: bold !important;
	}
}
@media screen and (max-width: 600px) {
	#mapMarina {
		height:  37vh;
	}
	#mapNearest {
		min-height:  100%;
	}
	.content {
		padding: 0px 10px 0px 10px;
	}
	.modalPhone {
		width: 78%;
	}
  .breadcrumb {
    display: none;
  }
}
@media screen and (min-width: 601px) {
	#mapMarina {
		height:  37vh;
	}
	#mapNearest {
		min-height:  100%;
	}
}
@media screen and (max-width: 1200px) {
	#mapNearest {
		display: none;
	}
	.contactButton .buttonTitle,	.itineraryButton .buttonTitle, .categorizeButton .buttonTitle {
		display: none;
	}
	.sendRequestButton {
		max-width: inherit !important;
		width: inherit !important;
	}
}
@media screen and (min-width: 901px) {
	.marinaTopInfoContent {
		min-height: 450px;
	}
	.marinaImgMobile {
		display: none;
	}
	.priceSlideUp {
		display: none !important;
	}
}
@media screen and (max-width: 1200px) {
  .sendRequestButton {
    min-width: 130px !important;
  }
}

@media screen and (min-width: 1201px) {
  .contactButton, .itineraryButton, .categorizeButton, .sendRequestButton {
    min-width: 130px !important;
  }
}

