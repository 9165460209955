.card {
	padding: 5px;
	max-width: 100% !important;
}
.cardInfo {
	background: #FFFFFF;
	box-shadow: 3px 6px 12px rgba(0, 0, 0, 0.1);
	border-radius: 0px 11px 11px 11px;
    overflow:  hidden;
    position: relative;
}
.cardInfo:hover, .cardInfoMobile:hover {
	opacity: 0.5;
}
.cardInfoMobile {
	display: none;
	height: 100px;
	background-color:  white;
	border-radius: 0px 11px 11px 11px;
    position: relative;
    overflow: hidden;
}
.cardDistance {
	width: 99px;
	height: 25px;
	top:  -30px;
	padding:  5px 0px 0px 0px;

	background: #1E5891;
	box-shadow: 3px 6px 12px rgba(0, 0, 0, 0.1);
	border-radius: 11px 11px 0px 0px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 11pt;
}
.cardTitle {
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	white-space: nowrap;
	font-style: normal;
	font-weight: 700;
	font-size: 12pt;
	line-height: 19px;
	padding-left: 10px;
	color: #0098FF;
	padding-top:  10px;
}
.cardLocalisation {
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	white-space: nowrap;
	color: black;
	padding-left: 10px;
	font-size: 10pt;
	padding-bottom:  10px;
}
.cardImg {
  	object-fit: cover;
	width: 100%;
	background-color: rgba(7, 125, 193, 0.2);
	height: 154px;
}

.cardPrice {
	padding:  10px;
	color: white;
	font-size:  30pt;
	font-weigth: bold;
	max-width:  150px;
	border-radius:  4px;
}
.cardPurcent {
	position: relative;
    top: 10%;
    right: 5%;
    width: 55px;
    text-align: center;
    color: white;
    font-weight: bold;
    height: 21px;
    font-size: 11pt;
	border-radius: 35px;
    padding-top: 5px;
    margin-top: 5px;
}
.cardBottom {
	height: 10px;
	width:  40%;
	position:  relative;
	bottom:  8px;
}
.cardStar img {
	position: absolute;
	right:  10px;
	width: 30px;
}
.cardInfo .cardImgContainer {
	position: relative;
}
@media screen and (max-width: 600px) {
	.card {
		max-width: 100% !important;
		margin: 5px !important;
	}
	.cardInfo {
		display: none;
	}
	.cardInfoMobile {
		display: block;
	}
	.cardImg {
		height: 100%;
		width: 100%;
	}
	.cardImgContainer {
		height: 100%;
	}
	.cardLocalisation {
		padding-bottom: inherit;
		max-width: 200px;
	}
	.cardPrice {
		padding: inherit;
	}
}
@media screen and (max-width: 900px) {
	.cardLocalisation {
		max-width: 200px;
	}
}
@media screen and (max-width: 1200px) {
	.cardLocalisation {
		max-width: 200px;
	}
}
@media screen and (max-width: 1536px) {
	.cardLocalisation {
		max-width: 235px;
	}
}
@media screen and (min-width: 1536px) {
	.cardLocalisation {
		max-width: 224px;
	}
}
