nav {
  position: fixed;
  top: 0px;
  width: 100%;
  background: white;
  z-index: 1299;
  height: 8vh;
}
nav a {
    text-decoration: none;
}
nav a, nav a:visited, nav a:hover, nav a:active {
  color: inherit;
}
.MuiAppBar-colorPrimary {
  background-color: white !important;
}
.logo {
  width: 360px;
}
.topTitle {
  color: #1E5891;  
}
@media screen and (max-width: 600px) {
  .logo {
    width: 200px;
  }
}
@media screen and (min-width: 601px) and (max-width: 901px) {
  .logo {
    width: 300px;
  }
}

