.buttonWithIcon {
  background-color: #46B9CC !important;
  min-height: 40px;
  text-align: right;
}
.buttonWithIcon:hover {
  background-color: #4fd8f0 !important;
}
@media screen and (max-width: 1200px) {
  .buttonIcon {
    position: relative;
  }
  .buttonWithIcon {
    height: 50px;
    text-align: center;
    padding-top: 13px !important;
    min-width: inherit !important;
    width: 50px;
  }
}